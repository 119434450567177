import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { getUserIdFromAuth } from "../Redux/actions/GetSellerIdFromAuthActionCreators";
import { getRideId } from "../Redux/actions/getRideId";
function SuperAdmin() {
  const baseUrl = "https://server.youthbuzz.in";

  const id = useSelector((state) => state.get_seller_profile_id.user_id);
  const role2 = useSelector((state) => state.get_seller_profile_id.role);
  const ride_id = useSelector((state) => state.get_Ride_id.Ride_id);

  console.log(ride_id);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState("");
  const [search, setSearch] = useState("");
  const [button, showButton] = useState(true);
  const [filteredData, setFilteredData] = useState({
    session: [],
    achievement: [],
  });
  const [rideName, setRideName] = useState("");
  const [rideId, setRideId] = useState("");
  const [rideDescription, setRideDescription] = useState("");
  const [rideLocation, setRideLocation] = useState("");
  const [rideThumnail, setRideThumnail] = useState("");
  const [rideGallery, setRideGallery] = useState([]);

  const handleRide = async (e) => {
    e.preventDefault();
    showButton(false);
    try {
      const res = await axios.post(`${baseUrl}/api/v1/ride/createRide`, {
        Location: rideLocation,
        Name: rideDescription,
        Description: rideDescription,
        RideID: rideId,
      });
      if (res.data.status === "Success") {
        dispatch(getRideId(res.data.Data.session._id));
        alert("Ride Created");
        setShow("ridephoto");
        showButton(true);
      }
    } catch (error) {
      alert("Somthing went wrong");
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    showButton(false);
    if (password !== confirm_password) {
      alert("Passwords do not match");
      showButton(true);
      return; // Prevent form submission
    }

    try {
      const response = await axios.post(`${baseUrl}/api/v1/admin/signup`, {
        // lastname:lastname,
        email: email,
        password: password,
        confirm_password: confirm_password,
        role: role,
        location: location,
        name: name,

        headers: {
          Authorization: `Bearer ${token}`,
        },

        // isEmailVerified: isEmailVerified
      });

      if (response.data.status === false) {
        alert("Email already registered ");
      }
      if (response.data.statusbar === "success") {
        alert("Created");
        showButton(true);
      }
      // if (response.data.statusbar === "success") {
      //   dispatch(getUserIdFromAuth(response.data.data.user._id, response.data.data.user.lastname, response.data.data.user.name, response.data.data.user.email));
      //   navigate("/home")
      // }
    } catch (error) {
      console.log(error);
      alert("Email already registered or  somthing went wrong");
      showButton(true);
      // if (error.message === "Request failed with status code 403") {
      //   setsign("OTP")

      // }
    }
  };
  const [deleteshow, setDelete] = useState(true);
  const [time, setTime] = useState("UTC");

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("ArcadeAdmin");
  const [location, setLocation] = useState("");

  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm] = useState("");
  const [token, setToken] = useState("");
  const convertToIST = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    const date = new Date(0, 0, 0, hours, minutes, seconds);
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);
    return date.toTimeString().split(" ")[0];
  };
  const convertToSpainTime = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    const date = new Date(0, 0, 0, hours, minutes, seconds);
    date.setHours(date.getHours() + 2);
    date.setMinutes(date.getMinutes() + 0);
    return date.toTimeString().split(" ")[0];
  };
  const convertToUSA = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    const date = new Date(0, 0, 0, hours, minutes, seconds);
    date.setHours(date.getHours() - 4);
    date.setMinutes(date.getMinutes() + 0);
    return date.toTimeString().split(" ")[0];
  };
  const convertToUk = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    const date = new Date(0, 0, 0, hours, minutes, seconds);
    date.setHours(date.getHours() + 1);
    date.setMinutes(date.getMinutes() + 0);
    return date.toTimeString().split(" ")[0];
  };
  useEffect(() => {
    if (!id || !role || role2 !== "SuperAdmin") {
      navigate("/login", { replace: true, state: { signIn: true } });
    } else {
      navigate("/superAdmin");
    }
  }, [navigate, id, role]);
  const logout = () => {
    dispatch(getUserIdFromAuth(""));
    navigate("/login");
  };

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const GetData = async () => {
    try {
      const res = await axios.get(
        "https://server.youthbuzz.in/api/v1/session/get"
      );
      setData(res.data.Data.session.reverse());
      
      console.log(res.data.Data.session)
    } catch (error) {
      console.error(error);
    }
  };
 
  const GetData3 = async () => {
    try {
      const res = await axios.get(
        "https://server.youthbuzz.in/api/v1/admin/getAllAdmin"
      );
      setData3(res.data.data.admins);
    } catch (error) {
      console.error(error);
    }
  };
  const GetData4 = async () => {
    try {
      const res = await axios.get(
        "https://server.youthbuzz.in/api/v1/ride/getRide"
      );
      setData4(res.data.data.ride);
    } catch (error) {
      console.error(error);
    }
  };
  const delete1 = async (id) => {
    try {
      const res = await axios.delete(
        `https://server.youthbuzz.in/api/v1/session/delete/${id}`
      );
      alert("Deleted");
    } catch (error) {
      console.error(error);
    }
  };

  const delete2 = async (id) => {
    try {
      const res = await axios.delete(
        `https://server.youthbuzz.in/api/v1/achievement/delete/${id}`
      );
      alert("Deleted");
    } catch (error) {
      console.error(error);
    }
  };
  const delete3 = async (id) => {
    try {
      const res = await axios.delete(
        `https://server.youthbuzz.in/api/v1/admin/delete/${id}`
      );
      alert("Deleted");
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAll1 = async () => {
    try {
      const res = await axios.delete(
        `https://server.youthbuzz.in/api/v1/session/deleteAll`
      );
      alert("Deleted all session data");
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAll2 = async () => {
    try {
      const res = await axios.delete(
        `https://server.youthbuzz.in/api/v1/achievement/deleteAll`
      );
      alert("Deleted all achievement data");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
 GetData()
    GetData4()
    GetData();
    GetData3();
  }, []);

   useEffect(() => {
    if (search.trim() === "") {
      setFilteredData(data); // Show original data if search is empty
    } else {
      const filteredSessionData = data.filter((item) => {
        return (
          item._id.toLowerCase().includes(search.toLowerCase()) ||
          item.Date.toLowerCase().includes(search.toLowerCase()) ||
          item.Location.toLowerCase().includes(search.toLowerCase())
        );
      });
      setFilteredData(filteredSessionData);
    }
  }, [search, data]);

  const handleFileChange = (event) => {
    console.log(event.target.value);
    setRideThumnail(event.target.files[0]);
  };

  const handleSubmitphoto = async (e) => {
    e.preventDefault();
    showButton(false);
    const formData = new FormData();
    formData.append("Thumbnail", rideThumnail);
    try {
      const response = await axios.patch(
        `${baseUrl}/api/v1/ride/Thumbnail/${ride_id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response.data);
      if (response.data.status === "success") {
        showButton(true);
        alert("Ride thumnail uploaded");
      }
      // setFile(response.data);
    } catch (error) {
      alert("Somthing went wrong");
      showButton(true);
    }
  };
  const handleFileChange2 = (event) => {
    console.log(event.target.value);
    const files = event.target.files;
    setRideGallery(Array.from(files)); // Set state with an array of files
  };

  const handleSubmitphoto2 = async (e) => {
    e.preventDefault();
    showButton(false);

    const formData = new FormData();
    // Append each file to the formData
    rideGallery.forEach((file, index) => {
      formData.append(`Gallery`, file);
    });

    try {
      const response = await axios.patch(
        `${baseUrl}/api/v1/ride/Gallery/${ride_id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response.data);
      if (response.data.status === "success") {
        showButton(true);
        alert("Ride thumbnail uploaded");
      }
      // setFile(response.data);
    } catch (error) {
      alert("Something went wrong");
      showButton(true);
    }
  };

  return (
    <>
      <select value={time} onChange={(e) => setTime(e.target.value)}>
        <option value="UTC">UTC</option>
        <option value="ist">IST</option>
        <option value="Spain">Spain</option>
        <option value="usa">USA</option>
        <option value="UK">UK</option>
      </select>
      {show === "achiveDelete" && (
        <div
          style={{
            background: "white",
            color: "black",
            width: "300px",
            margin: "auto",
            padding: "50px",
          }}
        >
          Do you want to delete all achievement data?
          <button onClick={deleteAll1}>Yes</button>
          <button onClick={() => setShow("achiv")}>No</button>
        </div>
      )}
      {show === "sessionDelete" && (
        <div
          style={{
            background: "white",
            color: "black",
            width: "300px",
            margin: "auto",
            padding: "50px",
          }}
        >
          Do you want to delete all session data?
          <button onClick={deleteAll2}>Yes</button>
          <button onClick={() => setShow("session")}>No</button>
        </div>
      )}
      <button
        onClick={logout}
        style={{ position: "fixed", top: "10px", right: "0px" }}
        className="login-btn"
      >
        LOGOUT
      </button>
      <input
        type="text"
        placeholder="Search..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{ marginBottom: "20px" }}
      />
      <button onClick={() => setShow("achiv")}>Achievements</button>
      <button onClick={() => setShow("session")}>Session</button>
      <button onClick={() => setShow("create")}>Create ArcadeAdmin</button>
      <button onClick={() => setShow("get")}>Delete Admins</button>
      <button onClick={() => setShow("ride")}>Create Ride</button>
      <button onClick={() => setShow("getRide")}>Update ride</button>
      {show === "session" && (
        <div>
          <button onClick={() => setShow("sessionDelete")}>
            Delete All Session Data
          </button>
          <table
            bgcolor="#0D4F74"
            cellSpacing="0px"
            style={{ margin: "50px auto" }}
          >
            <thead>
              <tr>
                <th>Sno.</th>
                <th>Session no.</th>
                <th>Date</th>
                <th>Duration</th>
                <th>Start time</th>
                <th>End time</th>
                <th>Location</th>
                <th>Total players</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item._id}</td>
                  <td>{item.Date}</td>
                  <td>{item.Duration}</td>
                  <td>
                    {" "}
                    {time === "UK" && <>{convertToUk(item.StartTime)}</>}
                    {time === "Spain" && (
                      <>{convertToSpainTime(item.StartTime)}</>
                    )}
                    {time === "ist" && <>{convertToIST(item.StartTime)}</>}
                    {time === "usa" && <>{convertToUSA(item.StartTime)}</>}
                    {time === "UTC" && <>{item.StartTime}</>}
                  </td>
                  <td>
                    {" "}
                    {time === "UK" && <>{convertToUk(item.EndTime)}</>}
                    {time === "Spain" && (
                      <>{convertToSpainTime(item.EndTime)}</>
                    )}
                    {time === "ist" && <>{convertToIST(item.EndTime)}</>}
                    {time === "usa" && <>{convertToUSA(item.EndTime)}</>}
                    {time === "UTC" && <>{item.EndTime}</>}
                  </td>
                  <td>{item.Location}</td>
                  <td>{item.TotalPlayers}</td>
                  <td>
                    {deleteshow ? (
                      <button onClick={() => delete1(item._id)}>Delete</button>
                    ) : (
                      <button>Deleted</button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {show === "achiv" && (
        <div>
          <button onClick={() => setShow("achiveDelete")}>
            Delete All Achievement Data
          </button>
        



          <table  bgcolor="#0D4F74"
            cellSpacing="0px"
            style={{ margin: "50px auto" }}>
        <thead>
          <tr>
            <th>#</th>
            <th>ID</th>
            <th>Date</th>
            <th>Duration</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Location</th>
            <th>Total Players</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item._id}</td>
              <td>{item.Date}</td>
              <td>{item.Duration}</td>
              <td>
                {time === "UK" && <>{convertToUk(item.StartTime)}</>}
                {time === "Spain" && <>{convertToSpainTime(item.StartTime)}</>}
                {time === "ist" && <>{convertToIST(item.StartTime)}</>}
                {time === "usa" && <>{convertToUSA(item.StartTime)}</>}
                {time === "UTC" && <>{item.StartTime}</>}
              </td>
              <td>
                {time === "UK" && <>{convertToUk(item.EndTime)}</>}
                {time === "Spain" && <>{convertToSpainTime(item.EndTime)}</>}
                {time === "ist" && <>{convertToIST(item.EndTime)}</>}
                {time === "usa" && <>{convertToUSA(item.EndTime)}</>}
                {time === "UTC" && <>{item.EndTime}</>}
              </td>
              <td>{item.Location}</td>
              <td>{item.TotalPlayers}</td>
              <td>
                {deleteshow ? (
                  <button onClick={() => delete1(item._id)}>Delete</button>
                ) : (
                  <button>Deleted</button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
        </div>
      )}
      {show === "create" && (
        <div>
          <form className="admin-form" onSubmit={handleSignup}>
            <h2>Create ArcadeAdmin and ArcadeManager</h2>
            <h3></h3>
            <input
              className="login-form"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="enter arcadeadmin name"
              required
            ></input>
            <input
              className="login-form"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="enter arcadeadmin email"
              required
            ></input>
            <input
              className="login-form"
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder="enter arcadeadmin location"
              required
            ></input>
            <select
              className="login-form"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              style={{ width: "390px" }}
              required
            >
              <option value="ArcadeAdmin">ArcadeAdmin</option>
              <option value="ArcadeManager">ArcadeManager</option>
              <option value="SuperAdmin">SuperAdmin</option>
            </select>
            <input
        className="login-form"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Enter arcadeadmin password"
        minlength="8"
        required
        title="Password must be at least 8 characters long."
      />
      <input
        className="login-form"
        type="password"
        value={confirm_password}
        onChange={(e) => setConfirm(e.target.value)}
        placeholder="Enter arcadeadmin confirm password"
        minlength="8"
        required
        title="Please confirm your password."
      />
            {button ? (
              <button
                className="button-9"
                style={{ width: "200px" }}
                type="submit"
              >
                SUBMIT
              </button>
            ) : (
              <button
                className="button-9"
                disabled
                style={{ width: "200px" }}
                type="submit"
              >
                PLEASE WAIT
              </button>
            )}
          </form>
        </div>
      )}

      {show === "get" && (
        <div>
          {data3.map((item) => {
            return (
              <div key={item._id} className="admins">
                <h4>{item.name}</h4>
                <h4>{item.role}</h4>
                <h4>{item.email}</h4>
                <button className="button-9" onClick={() => delete3(item._id)}>
                  Delete
                </button>
              </div>
            );
          })}
        </div>
      )}
         {show === "getRide" && (
        <div>
          {data4.map((item) => {
            return (
              <div key={item._id} className="admins">
                <h4>{item.Name}</h4>
                         
              <Link to={`/updateRide/${item._id}`}>  <button className="button-9" onClick={() => delete3(item._id)}>
                Update
                </button></Link>
              </div>
            );
          })}
        </div>
      )}
      {show === "ride" && (
        <div>
          <form onSubmit={handleRide} className="admin-form">
            <h2>Create ride</h2>
            <h3></h3>
            <input
              type="text"
              className="login-form"
              placeholder="Ride name"
              onChange={(e) => setRideName(e.target.value)}
              required
              value={rideName}
            ></input>
            <input
              type="text"
              className="login-form"
              placeholder="Ride Id"
              required
              onChange={(e) => setRideId(e.target.value)}
              value={rideId}
            ></input>
            <input
              type="text"
              required
              className="login-form"
              placeholder="Ride location"
              onChange={(e) => setRideLocation(e.target.value)}
              value={rideLocation}
            ></input>
            <textarea
              className="login-form"
              rows="8"
              required
              placeholder="Ride description"
              onChange={(e) => setRideDescription(e.target.value)}
              value={rideDescription}
            ></textarea>
            {button ? (
              <button
                className="button-9"
                style={{ width: "200px" }}
                type="submit"
              >
                SUBMIT
              </button>
            ) : (
              <button
                className="button-9"
                disabled
                style={{ width: "200px" }}
                type="submit"
              >
                PLEASE WAIT
              </button>
            )}
          </form>
        </div>
      )}

      {show === "ridephoto" && (
        <div>
          {" "}
          <form onSubmit={handleSubmitphoto} className="admin-form">
            <h2>Upload thumnail</h2>

            <input
              type="file"
              className="login-form"
              placeholder="Thumbnail"
              onChange={handleFileChange}
              required
            ></input>
            {button ? (
              <button
                className="button-9"
                style={{ width: "200px" }}
                type="submit"
              >
                SUBMIT
              </button>
            ) : (
              <button
                className="button-9"
                disabled
                style={{ width: "200px" }}
                type="submit"
              >
                PLEASE WAIT
              </button>
            )}
          </form>
          <form
            style={{ marginTop: "20px" }}
            onSubmit={handleSubmitphoto2}
            className="admin-form"
          >
            <h2>Upload gallery</h2>
            <h4>Max 5 pics </h4>
            <input
              type="file"
              required
              className="login-form"
              placeholder="Tumbnail"
              onChange={handleFileChange2}
              multiple
            ></input>
            {button ? (
              <button
                className="button-9"
                style={{ width: "200px" }}
                type="submit"
              >
                SUBMIT
              </button>
            ) : (
              <button
                className="button-9"
                disabled
                style={{ width: "200px" }}
                type="submit"
              >
                PLEASE WAIT
              </button>
            )}
          </form>
        </div>
      )}
    </>
  );
}

export default SuperAdmin;
